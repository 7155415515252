.action {
	padding: 10px 20px;
	border-radius: 10px;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	transform: translateY(-2px);
	transition: transform 0.1s ease;
	box-shadow: 0 1px 3px 0 rgba($black, 0.25);
	// background-color: rgba($black, 0.1);
	@media (prefers-color-scheme: dark) {
		background-color: $secondary;
	}
	// border: 3px solid;
	&:active {
		transform: translateY(0);
		box-shadow: 0 0 1px 1px rgba($black, 0.5);
	}
	.icon {
		height: 50px;
		width: 50px;
		margin-right: 15px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-align: center;
		font-size: 1.5rem;
		border: 3px solid $primary;
		// box-shadow: 0 0 1px 0 $black inset;
		// background-color: rgba($black, 0.1);
		@media (prefers-color-scheme: dark) {
			border-color: $white;
			background-color: $white;
			color: $black;
			box-shadow: 0 0 1px 0 $white inset;
		}
		svg {
			width: 50px;
		}
	}
	.text {
		flex-grow: 1;
		font-size: 1rem;
		line-height: 1.25;
		.title {
			font-weight: bold;
		}
		.description {
			font-size: 0.8em;
		}
	}
	.plus {
		font-size: 1.5rem;
		color: $primary;
		@media (prefers-color-scheme: dark) {
			color: $white;
		}
	}
}
