.explore-nav {
	padding: 10px 15px 0;
	min-height: 60px;
	background-color: $white;
	box-shadow: 0 1px 5px 0 rgba($black, 0.05);
	@media (prefers-color-scheme: dark) {
		background-color: $black;
		box-shadow: 0 1px 5px 0 rgba($white, 0.05);
	}
	.explore-tabs {
		ul {
			position: relative;
			padding: 0;
			margin: 5px -15px 0;
			list-style: none;
			display: grid;
			grid-auto-flow: column;
			text-align: center;
			grid-auto-columns: 1fr;
			.line {
				height: 2px;
				// width: 25%;
				width: 33.33%;
				position: absolute;
				left: 0;
				bottom: 0;
				background-color: $primary;
				@media (prefers-color-scheme: dark) {
					background-color: $secondary;
				}
				transition: left 0.3s cubic-bezier(0.19, 1, 0.22, 1);
			}
			li {
				height: 40px;
				border-bottom: 2px solid transparent;
				cursor: pointer;
				position: relative;
				a {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					border-bottom: 2px solid transparent;
					&.active {
						pointer-events: none;
						// border-color: $primary;
						// @media (prefers-color-scheme: dark) {
						// 	border-color: $secondary;
						// }
					}
				}
			}
		}
	}
}
.explore-page {
	.container {
		padding: 0 15px;
	}
	main {
		padding: 15px 0;
		h3 {
			margin-bottom: 15px;
		}
		.search-results {
			list-style: none;
			padding: 0;
			margin: 0;
			&.reversed {
				display: flex;
				flex-direction: column-reverse;
			}
			.result {
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 15px;
				margin-bottom: 20px;
				.icon {
					height: 60px;
					width: 60px;
					border: 3px solid;
					border-color: $primary;
					@media (prefers-color-scheme: dark) {
						border-color: $secondary;
					}
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 1.25rem;
				}
				.text {
					flex: 1;
				}
				&[data-type="user"] {
					.username {
						font-weight: bold;
					}
					.name {
						opacity: 0.8;
						margin-bottom: 2px;
					}
					.followedby {
						font-size: 0.8rem;
						line-height: 1.2;
						opacity: 0.5;
						font-weight: bold;
					}
				}
				&[data-type="place"] {
					.name {
						font-weight: bold;
						font-size: 1.1rem;
					}
					.address {
						line-height: 1.3;
						opacity: 0.8;
						font-size: 0.9rem;
					}
				}
				&[data-type="drink"] {
					.name {
						font-weight: bold;
						font-size: 1.1rem;
					}
					.type {
						line-height: 1.3;
						opacity: 0.8;
					}
				}
			}
		}
	}
}
