.siteNav {
	padding: 10px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 1000;
	left: 0;
	top: 0;
	width: 100%;
	height: 70px;
	box-shadow: 0 1px 5px 0 rgba($black, 0.05);
	background-color: $black;
	color: $white;
	@media (prefers-color-scheme: dark) {
		box-shadow: 0 1px 5px 0 rgba($white, 0.05);
		background-color: $secondary;
	}
	.greeting {
		font-family: "Kaushan Script", cursive;
		font-size: 1.5rem;
		line-height: 0.8;
		span {
			font-size: 0.8em;
		}
	}
}
