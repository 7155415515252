.profile-drink {
	nav {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
	.illustration {
		width: 150px;
		height: 150px;
		margin: 0 auto;
		background: url("../assets/lager.png") top center/contain;
	}
	.hero {
		height: 180px;
		background: url("../assets/drink-header-lager.jpg") center/cover no-repeat
			#000;
	}
	.drink-nav {
		position: absolute;
		z-index: 100;
		top: 0;
		left: 0;
		right: 0;
		margin: 0;
		padding: 0 15px;
		height: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-align: center;
		gap: 15px;
		font-size: 1.25rem;
		background-color: transparent;
		color: white;
		.title {
			font-weight: bold;
			font-size: 1.125rem;
		}
		.cancel,
		.menu {
			width: 24px;
			cursor: pointer;
		}
	}
	main {
		margin-top: -20px;
		margin-left: -1px;
		margin-right: -1px;
		border-radius: 20px;
		background-color: #fcfcfc;
		@media (prefers-color-scheme: dark) {
			background-color: lighten($black, 2%);
		}
		padding: 15px 1px;
		.container {
			padding: 0 15px;
			header {
				text-align: center;
				padding: 20px 0;
				margin-bottom: 1rem;
				.type {
					text-transform: uppercase;
					margin-bottom: 0.5rem;
				}
				.title {
					font-size: 1.5rem;
					font-weight: bold;
					margin-bottom: 1rem;
				}
				.pills {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 10px;
				}
				.rating,
				.favourite {
					border-radius: 22px;
					line-height: 1;
					font-weight: bold;
					min-width: 70px;
					padding: 0 10px;
					height: 40px;
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 5px;
					.icon {
						font-size: 1em;
					}
				}
				.rating {
					background-color: rgba($primary, 0.5);
					@media (prefers-color-scheme: dark) {
						background-color: rgba($secondary, 0.5);
					}
				}
				.favourite {
					background-color: $black;
					color: $white;
					@media (prefers-color-scheme: dark) {
						background-color: $white;
						color: $black;
					}
				}
			}
			.description {
				text-align: center;
				.label {
					font-weight: bold;
				}
			}
			footer {
				text-align: center;
				padding: 20px 0;
				margin-top: 1rem;
				.button {
					&.active {
						color: $primary;
						@media (prefers-color-scheme: dark) {
							color: $secondary;
						}
					}
				}
			}
		}
	}

	.map-container {
		margin-top: 20px;
		background-color: rgba($black, 0.1);
	}
}
