.avatarIcon {
	border-radius: 50%;
	// overflow: hidden;
	max-width: 100%;
	border: 3px solid $primary;
	@media (prefers-color-scheme: dark) {
		border-color: $secondary;
	}
	position: relative;
	.avatar {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		border-radius: 50%;
		border: 3px solid $white;
		@media (prefers-color-scheme: dark) {
			border-color: $black;
		}
		&.image {
			object-fit: cover;
		}
		&.no-image {
			background-color: $black;
			@media (prefers-color-scheme: dark) {
				background-color: $white;
			}
		}
	}
}
