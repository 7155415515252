form {
	label {
		text-align: left;
		display: block;
		margin-bottom: 5px;
		font-weight: bold;
	}
	.error {
		text-align: center;
		font-weight: bold;
		color: $red;
		@media (prefers-color-scheme: dark) {
			color: $secondary;
		}
		// margin: 5px auto;
		// font-size: small;
	}
	.row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		& > div {
			flex: 1;
		}
	}
	.button,
	input,
	textarea {
		width: 100%;
		padding: 10px;
		background-color: rgba($black, 0.1);
		color: $black;
		@media (prefers-color-scheme: dark) {
			color: $white;
			background-color: rgba($white, 0.1);
		}
		margin-bottom: 15px;
		border-radius: 10px;
		border: none;
	}
	.button {
		margin-top: 0;
		display: flex;
		font-weight: bold;
		align-items: center;
		justify-content: center;
		gap: 10px;
		background-color: $primary;
		color: $white;
		&:hover {
			background-color: darken($primary, 10);
		}
		@media (prefers-color-scheme: dark) {
			background-color: $white;
			color: $black;
		}
		&:active {
			background-color: $black;
		}
		&.button-brand {
			background-color: lighten($black, 40%);
			@media (prefers-color-scheme: dark) {
				background-color: darken($white, 40%);
			}
			&:active {
				background-color: lighten($black, 20%);

				@media (prefers-color-scheme: dark) {
					background-color: darken($white, 20%);
				}
			}
		}
	}
	input,
	textarea {
		&.input-error {
			border: 2px solid $red;
		}
	}
	.link {
		text-align: right;
		cursor: pointer;
	}
	.sign-up-text {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 60px;
		margin-bottom: 15px;
		&::before {
			position: absolute;
			content: "";
			height: 2px;
			width: 100%;
			background-color: rgba($black, 0.1);
			@media (prefers-color-scheme: dark) {
				background-color: rgba($white, 0.1);
			}
		}
		div {
			position: relative;
			background-color: $white;
			display: inline-block;
			margin: 0 auto;
			padding: 0 15px;
		}
	}
	.update-avatar {
		text-align: center;
		display: inline-block;
		position: relative;
		font-weight: bold;
		.avatars {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 10px;
			margin-bottom: 10px;
			.avatar {
				&.-left,
				&.-right,
				&.--left,
				&.--right {
					.avatarIcon {
						border: none;
					}
					display: none;
				}
				@media screen and (min-width: 480px) {
					&.--left,
					&.--right {
						opacity: 0.1;
						display: block;
					}
				}
				@media screen and (min-width: 380px) {
					&.-left,
					&.-right {
						opacity: 0.4;
						display: block;
					}
				}
			}
			.arrow {
				cursor: pointer;
				font-size: 2rem;
			}
		}
	}
	fieldset {
		input {
			margin-bottom: 0;
		}
	}
	.dropdown {
		position: relative;
		height: 40px;
		display: flex;
		align-items: center;
		border-radius: 10px;
		padding-right: 10px;
		width: 100%;
		background: rgba($black, 0.1);
		@media (prefers-color-scheme: dark) {
			background: rgba($white, 0.1);
		}

		/* styling of Input */
		.input {
			background-color: transparent;
			width: 100%;
			height: 100%;
			padding-inline: 0.5em;
			padding-block: 0.7em;
			border: none;
			color: $black;
			margin: 0;
			@media (prefers-color-scheme: dark) {
				color: $white;
			}
		}
	}
}
