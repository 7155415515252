.location-nav {
	padding: 0 15px;
	height: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	gap: 15px;
	font-size: 1.25rem;
	background-color: $white;
	box-shadow: 0 1px 5px 0 rgba($black, 0.05);
	@media (prefers-color-scheme: dark) {
		background-color: $black;
		box-shadow: 0 1px 5px 0 rgba($white, 0.05);
	}
	.title {
		font-weight: bold;
		font-size: 1.125rem;
	}
	.cancel,
	.menu {
		width: 24px;
		cursor: pointer;
	}
}

.profile-location {
	.container {
		padding: 0 15px;

		.wifi {
			span {
				user-select: text;
				cursor: pointer;
			}
		}
		.map-container {
			margin: 0 -15px 20px;
		}
		form {
			padding: 15px 0;
		}
	}
}
