.map-container {
	height: 200px;
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	.pin {
		position: absolute;
		bottom: 50%;
		left: 50%;
		z-index: 1;
		font-size: 2rem;
		transform: translateX(-50%);
		color: $primary;
		@media (prefers-color-scheme: dark) {
			color: $secondary;
		}
	}
	img {
		// max-width: 100%;
		width: 100%;
		height: 200px;
		object-fit: cover;
	}
}
