.history-nav {
	padding: 0 15px;
	height: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	gap: 15px;
	font-size: 1.25rem;
	background-color: $white;
	box-shadow: 0 1px 5px 0 rgba($black, 0.05);
	@media (prefers-color-scheme: dark) {
		background-color: $black;
		box-shadow: 0 1px 5px 0 rgba($white, 0.05);
	}
	.title {
		font-weight: bold;
		font-size: 1.125rem;
	}
	.cancel,
	.menu {
		width: 24px;
		cursor: pointer;
	}
}

.history-page {
	.container {
		padding: 0 15px;

		main {
			padding: 0 0 15px;
		}
	}
	.visits {
		text-align: center;
		margin: 40px 0;
		.visit {
			.location {
				text-align: center;
				padding: 5px 15px;
				border-top: 1px solid;
				border-bottom: 1px solid;
				margin: 0 -15px 15px;
				border-color: $primary;
				background-color: rgba($primary, 0.05);
				@media (prefers-color-scheme: dark) {
					border-color: $secondary;
					background-color: rgba($secondary, 0.05);
				}
				.name {
					font-weight: bold;
				}
			}
			.attendees {
				margin: 10px 0;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 15px;
				.avatarIcon img {
					border: none;
				}
			}
		}
	}
	.drinks {
		list-style: none;
		padding: 0;
		margin: 0 0 20px;
		.drink {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 10px;
			gap: 5px;
			span {
				display: inline-block;
				white-space: nowrap;
			}
			.title {
				font-weight: bold;
				line-height: 1;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.time,
			.description {
				opacity: 0.8;
				font-size: 0.9em;
			}
		}
	}
}
