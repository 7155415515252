.profile-nav {
	padding: 0 15px;
	height: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 15px;
	font-size: 1.25rem;
	background-color: $white;
	box-shadow: 0 1px 5px 0 rgba($black, 0.05);
	@media (prefers-color-scheme: dark) {
		background-color: $black;
		box-shadow: 0 1px 5px 0 rgba($white, 0.05);
	}
	.title {
		font-weight: bold;
		font-size: 1.125rem;
	}
	.cancel,
	.menu {
		width: 24px;
		cursor: pointer;
	}
}
.profile-page {
	.container {
		padding: 0 15px;
		header {
			text-align: center;
			padding: 15px 0;
			.avatarIcon {
				position: relative;
				margin: 0 auto 5px;
				&:before,
				&:after {
					content: "";
					position: absolute;
					display: block;
					height: 100px;
					width: 136px;
					top: 0;
					background: url("../assets/flash.svg") no-repeat;
					@media (prefers-color-scheme: dark) {
						background-image: url("../assets/flash-inv.svg");
					}
				}
				&:before {
					right: 120%;
					transform: scaleX(-1);
				}
				&:after {
					left: 120%;
				}
			}
			.name {
				font-weight: bold;
				font-family: "Kaushan Script", cursive;
				font-size: 1.5em;
				margin: 15px 0;
			}
			.actions {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 10px;
				margin: 30px 0 10px;
				.button {
					margin-top: 0;
				}
				button {
					width: 100%;
					transition: 0.1s ease-in-out;
					&:hover {
						background-color: $primary;
						border-color: $primary;
						@media (prefers-color-scheme: dark) {
							background-color: $secondary;
							border-color: $secondary;
						}
					}
				}
			}
		}
	}
}
