.statsW {
	margin: 10px 0 30px;
}

.stats {
	display: grid;
	grid-auto-flow: column;
	text-align: center;
	grid-auto-columns: 1fr;
}

.stat {
	margin-bottom: 20px;
	flex-grow: 1;
	.icon {
		// cursor: pointer;
		margin: 0 auto 5px;
		height: 40px;
		width: 40px;
		border-radius: 50%;
		border: 3px solid;
		background-color: $black;
		color: $white;
		border-color: $black;
		@media (prefers-color-scheme: dark) {
			border-color: $white;
			background-color: $secondary;
			color: $white;
		}
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.number {
		font-weight: bold;
		font-size: 0.9em;
		line-height: 1;
		text-transform: uppercase;
	}
	.title {
		font-size: 0.7em;
		opacity: 0.65;
	}
}
