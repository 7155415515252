.auth-page {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: $blue;
	color: white;
	@media (prefers-color-scheme: dark) {
		background-color: $black;
	}
	.dot-spinner__dot::before {
		background-color: $white;
	}
	.container {
		padding: 20px;
		width: 400px;
		max-width: 100%;
		h1 {
			font-size: 1.5rem;
			margin-bottom: 20px;
			font-weight: bold;
		}
		a:hover {
			color: $red;
		}
		form {
			input,
			textarea {
				background-color: rgba($white, 0.95);
				color: $black;
			}
			.button {
				background-color: $black;
				color: $white;
			}
		}
	}
}
