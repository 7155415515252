.search-bar {
	position: relative;
	width: 200px;
	height: 40px;
	display: flex;
	align-items: center;
	padding-inline: 0.8em;
	border-radius: 30px;
	transition: border-radius 0.5s ease;
	background: rgba($black, 0.1);
	@media (prefers-color-scheme: dark) {
		background: rgba($white, 0.1);
	}
	width: 100%;

	/* styling of animated border */
	&:before {
		content: "";
		position: absolute;
		background: $black;
		@media (prefers-color-scheme: dark) {
			background: $white;
		}
		transform: scaleX(0);
		transform-origin: center;
		width: 100%;
		height: 2px;
		left: 0;
		bottom: 0;
		border-radius: 1px;
		transition: transform 0.3s ease;
	}

	/* Hover on Input */
	&:focus-within {
		border-radius: 1px;
		&:before {
			transform: scale(1);
		}
	}

	/* styling of Input */
	.input {
		background-color: transparent;
		width: 100%;
		height: 100%;
		padding-inline: 0.5em;
		padding-block: 0.7em;
		border: none;
		color: $black;
		margin: 0;
		@media (prefers-color-scheme: dark) {
			color: $white;
		}

		&:focus {
			outline: none;
		}
	}
}

.search-results {
	// max-height: 400px;
	overflow-y: scroll;
	// border-bottom: 1px solid rgba(37, 50, 89, 0.1);
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		border-left: 1px solid rgba(37, 50, 89, 0.1);
		border-right: 1px solid rgba(37, 50, 89, 0.1);
		li {
			padding: 10px 20px;
			cursor: pointer;
			& + li {
				border-top: 1px solid rgba(37, 50, 89, 0.1);
			}
			&:hover {
				background-color: rgba(37, 50, 89, 0.1);
			}
			.result {
				margin: 0;
			}
		}
		&.active {
			display: block;
		}
	}
}
